import React from "react";
import Container from "../container/container";
import carPhotoDesktop from "../../images/contactDesktop.webp";
import carPhotoMobile from "../../images/contactDesktop.webp";

import * as StyleComponents from "./contactPage.module.scss";

// markup
export const ContactPageComponent = () => {
  return (
    <Container>
      <picture>
        <source
          media="(max-width: 1200px)"
          type="image/jpeg"
          srcSet={carPhotoMobile}
        />
        <img
          src={carPhotoDesktop}
          alt="Jeep 4x4 canada offroad with big tires"
          className={StyleComponents.mainImage}
        />
        <div className={StyleComponents.form}>
          <h3>
            Téléphone :&nbsp;<a href="tel:450-397-0698">450-397-0698</a>
          </h3>
          <h3>
            Numéro sans frais :&nbsp;
            <a href="tel:+1 833-333-2992">+1 833-333-2992</a>
          </h3>
          <h3>
            Courriel :&nbsp;
            <a href="mailto: info@canadaoffroad.com">info@canadaoffroad.com</a>
          </h3>
        </div>
      </picture>
    </Container>
  );
};
