import * as React from "react";
import { PageLayout } from "../components/layout/layout";
import { ContactPageComponent } from "../components/contactPage/contactPage";

// markup
const ContactPage = () => {
  return (
    <PageLayout>
      <ContactPageComponent />
    </PageLayout>
  );
};

export default ContactPage;
